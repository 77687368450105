import React from "react";
import { useNavigate } from "react-router-dom";

const CustomAnchor = ({ text, href, onClick }) => {
  return (
    <a
      href={href}
      onClick={onClick}
      style={{
        textDecoration: "none",
        color: "#FFFFFF",
        fontWeight: 600,
      }}
    >
      {text}
    </a>
  );
};

const Footer = ({ scrollToPrivacy, scrollToTerms, scrollToCookie }) => {
  const navigate = useNavigate();

  return (
    <div
      className="w-100 text-center"
      style={{
        backgroundColor: "#00a1c9",
        paddingTop: 60,
        paddingBottom: 60,
        whiteSpace: "nowrap",
      }}
    >
      <div
        className="row footer-menu gy-3"
        style={{
          marginLeft: "auto",
          marginRight: "auto",
          whiteSpace: "nowrap",
        }}
      >
        <div className="col">
          {/* Home link to navigate to the homepage */}
          <CustomAnchor
            text={"Home"}
            href="/"
            onClick={(e) => {
            //   e.preventDefault(); // Prevent the default anchor behavior
            //   navigate("/"); // Navigate to the homepage
            }}
          />
        </div>
        <div className="col">
          {/* Home link to navigate to the homepage */}
          <CustomAnchor
            text={"Terms and Conditions"}
            href="/"
            onClick={(e) => {
              e.preventDefault(); // Prevent the default anchor behavior
              scrollToTerms()
            }}
          />
        </div>
        <div className="col">
          {/* Home link to navigate to the homepage */}
          <CustomAnchor
            text={"Privacy Policy"}
            href="/"
            onClick={(e) => {
              e.preventDefault(); // Prevent the default anchor behavior
              scrollToPrivacy()
            }}
          />
        </div>
        <div className="col">
          {/* Home link to navigate to the homepage */}
          <CustomAnchor
            text={"Cookie Policy"}
            href="/"
            onClick={(e) => {
              e.preventDefault(); // Prevent the default anchor behavior
              scrollToCookie()
            }}
          />
        </div>
      </div>

      <div
        className="row mt-5"
        style={{
          marginLeft: "auto",
          marginRight: "auto",
          width: 300,
          fontSize: 14,
          opacity: 0.7,
          color: "#FFFFFF",
        }}
      >
        <div>
          <span>© All Rights Reserved. BarX</span>
        </div>
        <div>
          <span>admin@barx.ae</span>
        </div>
      </div>
    </div>
  );
};

export default Footer;
