import React, { useRef } from "react";
import AboutUs from "./components/AboutUs";
import Header from "./components/Header";
import OurMission from "./components/OurMission";
import OurProducts from "./components/OurProducts";
import Carousel from "./components/Carousel";
import OurBenefits from "./components/OurBenefits";
import OurPartners from "./components/OurPartners";
import Contacts from "./components/Contacts";
import Map from "./components/Map";
import Footer from "./components/Footer";
import banner from "./assets/banner.jpg";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import TermsAndConditions from "./components/T&Cs/TermsAndConditions";

const App = () => {
  const missionRef = useRef(null);
  const aboutRef = useRef(null);
  const productsRef = useRef(null);
  const galleryRef = useRef(null);
  const benefitsRef = useRef(null);
  const partnersRef = useRef(null);
  const contactsRef = useRef(null);

  const scrollToView = (ref) => {
    if (!ref) return;
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <>
     <Router>
  <Routes>
    <Route
      path="/"
      element={
        <>
          <Header
            missionRef={missionRef}
            aboutRef={aboutRef}
            productsRef={productsRef}
            galleryRef={galleryRef}
            benefitsRef={benefitsRef}
            partnersRef={partnersRef}
            contactsRef={contactsRef}
            scrollIntoView={scrollToView}
          />
          <div className="container-nav"></div>
          <div>
            <img src={banner} alt="happyTogether" width={"100%"} height={"auto"} />
          </div>
          <OurMission reference={missionRef} />
          <AboutUs reference={aboutRef} />
          <OurProducts reference={productsRef} />
          <Carousel reference={galleryRef} />
          <OurBenefits reference={benefitsRef} />
          <OurPartners reference={partnersRef} />
          <Contacts reference={contactsRef} />
          <Map />
          <Footer
            aboutRef={aboutRef}
            productsRef={productsRef}
            galleryRef={galleryRef}
            benefitsRef={benefitsRef}
            contactsRef={contactsRef}
            scrollIntoView={scrollToView}
          />
        </>
      }
    />
    <Route path="/terms" element={<TermsAndConditions />} />
  </Routes>
</Router>


    </>
  );
};

export default App;
