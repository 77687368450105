import React from "react";
import BodyText from "../../common/BodyText";
import TitleText from "../../common/TitleText";

const AboutUs = ({ reference }) => {
  return (
    <>
      <div ref={reference} style={{ height: 125 }} />
      <div
        className="text-center"
        style={{
          backgroundColor: "#00a9c1",
          paddingTop: 60,
          paddingBottom: 60,
        }}
      >
        <TitleText text={"ABOUT US"} color="#FFFFFF" />
        <div
          className="divider"
          style={{
            maxWidth: 100,
            backgroundColor: "#FFFFFF",
            height: 3,
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: 40,
            marginBottom: 40,
          }}
        />
        <div className="mt-3">
          <BodyText
            text={`Barx is a veterinary pharmaceutical and pet food trading company dedicated to supporting customers and their businesses in innovative ways. Building on more than 25 years of experience in the veterinary field, Barx provides quality medication, vaccinations, pet food and snacks. We are working day in and day out to better understand and address the struggles faced by those who raise and care for animals from their perspective and as it is relevant to them. This founding principle continues to guide and motivate us every day as we aspire to remain the leading veterinary medicine and pet food supplier in the region.`}
            color="#FFFFFF"
          />
        </div>
      </div>
    </>
  );
};

export default AboutUs;
