import React from "react";

const TitleText = ({ text, color = "black" }) => {
  return (
    <span
      className="title-text"
      style={{ fontWeight: 600, color, lineHeight: 1.23 }}
    >
      {text}
    </span>
  );
};

export default TitleText;
