import React from "react";
import TitleText from "../../common/TitleText";

const OurPartners = ({ reference }) => {
  return (
    <>
      <div
        className="text-center"
        style={{ marginTop: 125, overflow: "hidden" }}
      >
        <TitleText text={"OUR PARTNERS"} color={"#00a9c1"} />
        <div
          style={{
            marginLeft: "auto",
            marginRight: "auto",
            width: 350,
            marginTop: 100,
          }}
        >
          <div className="row">
            <div className="col">
              <a
                // href="https://gosbi.com/en/home-2/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="https://static.tildacdn.com/tild3061-6232-4938-a234-643862616461/gosbi.png"
                  alt="GOSBI"
                  style={{ maxWidth: 150 }}
                />
              </a>
            </div>
            <div className="col">
              <a
                // href="https://www.vetapro.net/en/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="https://static.tildacdn.com/tild6361-3138-4062-a534-373964326332/vetapro_logo.png"
                  alt="GOSBI"
                  style={{ maxWidth: 150 }}
                />
              </a>
            </div>
          </div>
        </div>
        <div ref={reference} style={{ height: 50 }} />
        <div>
          <img
            src="https://static.tildacdn.com/tild3462-6163-4761-b766-336232396634/banner-3.jpg"
            alt="PARTNERS"
            style={{ width: "100%" }}
          />
        </div>
      </div>
    </>
  );
};

export default OurPartners;
