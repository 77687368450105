import React from "react";
import { useNavigate } from "react-router-dom";
import payTypeImage1 from "./payTypeImages/1.png";
import payTypeImage2 from "./payTypeImages/2.png";
import payTypeImage3 from "./payTypeImages/3.png";
import payTypeImage4 from "./payTypeImages/4.png";
const CustomAnchor = ({ text, href, onClick }) => {
  return (
    <a
      href={href}
      onClick={onClick}
      style={{
        textDecoration: "none",
        color: "#FFFFFF",
        fontWeight: 600,
      }}
    >
      {text}
    </a>
  );
};

const Footer = ({
  aboutRef,
  productsRef,
  galleryRef,
  benefitsRef,
  contactsRef,
  scrollIntoView,
}) => {
  const navigate = useNavigate();
  const navigateToTerms = (section) => {
    navigate("/terms", { state: { scrollTo: section } });
  };

  return (
    <div
      className="w-100 text-center"
      style={{
        backgroundColor: "#00a1c9",
        paddingTop: 60,
        paddingBottom: 60,
        whiteSpace: "nowrap",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-evenly",
          // gap : "20px"
        }}
      >
        <div
          className="row footer-menu gy-3"
          style={{
            // marginLeft: "auto",
            // marginRight: "auto",
            whiteSpace: "nowrap",
          }}
        >
          <div className="col">
            <CustomAnchor
              text={"About"}
              href="#/"
              onClick={() => scrollIntoView(aboutRef)}
            />
          </div>
          <div className="col">
            <CustomAnchor
              text={"Products"}
              href="#/"
              onClick={() => scrollIntoView(productsRef)}
            />
          </div>
          <div className="col">
            <CustomAnchor
              text={"Gallery"}
              href="#/"
              onClick={() => scrollIntoView(galleryRef)}
            />
          </div>
          <div className="col">
            <CustomAnchor
              text={"Benefits"}
              href="#/"
              onClick={() => scrollIntoView(benefitsRef)}
            />
          </div>
          <div className="col">
            <CustomAnchor
              text={"Contacts"}
              href="#/"
              onClick={() => scrollIntoView(contactsRef)}
            />
          </div>
          <div className="col">
            <CustomAnchor
              text={"Terms and Conditions"}
              href="/terms"
              onClick={(e) => {
                e.preventDefault();
                navigateToTerms("terms");
              }}
            />
          </div>
          <div className="col">
            <CustomAnchor
              text={"Privacy Policy"}
              href="/terms"
              onClick={(e) => {
                e.preventDefault();
                navigateToTerms("privacy");
              }}
            />
          </div>
          <div className="col">
            <CustomAnchor
              text={"Cookie Policy"}
              href="/terms"
              onClick={(e) => {
                e.preventDefault();
                navigateToTerms("cookie");
              }}
            />
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div>
            <a
              style={{
                textDecoration: "none",
                color: "#FFFFFF",
                fontWeight: 600,
              }}
            >
              We Accept
            </a>
          </div>
          <div>
            <img
              src={payTypeImage1}
              style={{ maxHeight: "68px", maxWidth: "86px", margin:"3px 12px" }}
              alt="Pay Type 1"
            />
            <img
              src={payTypeImage2}
              style={{ maxHeight: "68px", maxWidth: "86px", margin:"3px 12px" }}
              alt="Pay Type 2"
            />
            <img
              src={payTypeImage3}
              style={{ maxHeight: "57px", maxWidth: "86px", margin:"3px 12px" }}
              alt="Pay Type 3"
            />
            <img
              src={payTypeImage4}
              style={{ maxHeight: "68px", maxWidth: "86px", margin:"3px 12px" }}
              alt="Pay Type 4"
            />
          </div>
        </div>
      </div>

      <div
        className="row mt-5"
        style={{
          marginLeft: "auto",
          marginRight: "auto",
          width: 300,
          fontSize: 14,
          opacity: 0.7,
          color: "#FFFFFF",
        }}
      >
        <div>
          <span>© All Rights Reserved. BarX</span>
        </div>
        <div>
          <span>admin@barx.ae</span>
        </div>
      </div>
    </div>
  );
};

export default Footer;
