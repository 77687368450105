import React from "react";
import TitleText from "../../common/TitleText";
import Item from "./Item";

const OurProducts = ({ reference }) => {
  return (
    <>
      <div ref={reference} style={{ height: 125 }} />
      <div>
        <div className="text-center">
          <TitleText text={"OUR PRODUCTS"} />
        </div>
        <div className="container" style={{ marginTop: 80 }}>
          <div className="col">
            <div className="row mb-5 gy-5">
              <Item
                title={"VETERINARY PHARMACEUTICALS"}
                body={`Veterinary pharmaceuticals, also known as medicinal products for veterinary use, veterinary drugs, or veterinary medicinal products (VMPs), are substances or combinations of substances to treat, prevent or diagnose disease in companion animals.`}
                src={`https://static.tildacdn.com/tild3065-3639-4336-a262-343538336461/icon-4.png`}
              />
              <Item
                title={"PET PRODUCTS"}
                body={`Our products are safe, non-toxic, and of the highest quality. We supply everything needed to groom your companion animal.`}
                src={`https://static.tildacdn.com/tild6261-6333-4233-b731-643135323866/icon-2.png`}
              />
            </div>
            <div className="row gy-5">
              <Item
                title={"FOOD & TREATS"}
                body={`Browse our range of healthy, grain-free dry and wet food. Our selection of treats are functional and include freeze-dried, chewy, dehydrated, training and dental treats, long-lasting chews and more…`}
                src={`https://static.tildacdn.com/tild3837-3335-4137-b431-336164373638/icon-1.png`}
              />
              <Item
                title={"ACCESSORIES"}
                body={`We have curated a portfolio that includes all the best for pets — from catnip toys to dog harnesses, collars and clothing.`}
                src={`https://static.tildacdn.com/tild3739-3137-4734-b034-663431383262/icon-5.png`}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OurProducts;
