import React from "react";
import TitleText from "../../common/TitleText";

const OurBenefits = ({ reference }) => {
  return (
    <>
      <div ref={reference} style={{ height: 125 }} />
      <div
        className="text-center"
        style={{
          backgroundColor: "#00a9c1",
          paddingTop: 60,
          paddingBottom: 60,
          overflow: "hidden",
        }}
      >
        <TitleText text={"OUR BENEFITS"} color="#FFFFFF" />
        <div className="container body-text" style={{ marginTop: 25 }}>
          <span
            style={{
              fontWeight: 700,
              color: "#FFFFFF",
              lineHeight: 1.55,
              fontSize: 22,
            }}
          >
            HEALTHY PRODUCTS TO HELP TAKE CARE OF THE PETS YOU LOVE
          </span>
        </div>
        <div className="row mt-2 gy-5">
          <div className="col-lg-4 col-sm-12">
            <img
              src="https://static.tildacdn.com/tild3034-3965-4139-a534-643061656135/barx_icon_1.png"
              alt="GRAIN-FREE"
              className="resp-image"
            />
            <br />
            <span
              style={{
                fontWeight: 700,
                color: "#FFFFFF",
                lineHeight: 1.55,
                fontSize: 22,
              }}
            >
              GRAIN-FREE
            </span>
          </div>
          <div className="col-lg-4 col-sm-12">
            <img
              src="https://static.tildacdn.com/tild3036-6632-4937-b666-616539336236/barx_icon_2.png"
              alt="HIGH-QUALITY-INGREDIENTS"
              className="resp-image"
            />
            <br />
            <span
              style={{
                fontWeight: 700,
                color: "#FFFFFF",
                lineHeight: 1.55,
                fontSize: 22,
              }}
            >
              HIGH-QUALITY INGREDIENTS
            </span>
          </div>
          <div className="col-lg-4 col-sm-12">
            <img
              src="https://static.tildacdn.com/tild3034-3132-4333-a339-616638356330/barx_icon_3.png"
              alt="CERTIFIED-PRODUCTS"
              className="resp-image"
            />
            <br />
            <span
              style={{
                fontWeight: 700,
                color: "#FFFFFF",
                lineHeight: 1.55,
                fontSize: 22,
              }}
            >
              CERTIFIED PRODUCTS
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default OurBenefits;
