import React from "react";
import GoogleMapReact from "google-map-react";

const Map = () => {
  const coordinates = {
    lat: 25.129160828605436,
    lng: 55.22510442520433,
  };

  const mapOptions = (maps) => {
    return {
      mapTypeId: maps.MapTypeId?.["ROADMAP"],
      mapTypeControl: true,
    };
  };

  const renderMarker = (map, maps) => {
    let marker = new maps.Marker({
      position: coordinates,
      map,
      title: "BarX",
    });
    return marker;
  };
  return (
    <>
      <div
        style={{
          marginTop: 125,
          width: "100%",
          height: 450,
          position: "absolute",
        }}
      >
        <GoogleMapReact
          options={mapOptions}
          bootstrapURLKeys={{ key: "AIzaSyDH8lsX7SdUPZedBOMZ5sZ1O79kgxUDmvU" }}
          center={coordinates}
          zoom={14}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => renderMarker(map, maps)}
        />
        <div
          style={{
            top: 10,
            left: 15,
            position: "absolute",
          }}
        ></div>
      </div>
      <div
        style={{
          marginTop: 100,
          width: "100%",
          height: 450,
        }}
      />
    </>
  );
};

export default Map;
