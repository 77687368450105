import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";

const Header = ({ scrollToPrivacy, scrollToTerms, scrollToCookie }) => {
  const [toggleMenu, setToggleMenu] = useState(false);

  return (
    <>
      {/* Overlay for the toggle menu (if needed in future) */}
      <div className={toggleMenu ? "overlay open" : "overlay"} id="overlay"></div>

      {/* Main Navigation Bar */}
      <nav
        className="navbar navbar-expand-lg fixed-top"
        style={{
          backgroundColor: "rgba(255,255,255,0.8)",
        }}
      >
        <div className="container resp-navbar">
          {/* Logo */}
          <a className="navbar-brand" href="/">
            <img
              src="https://static.tildacdn.com/tild3834-3939-4235-a139-653130366336/BaRX-Final_logo-Prim.png"
              height={"100px"}
              width={"200px"}
              alt="barx"
            />
          </a>

          {/* Toggle Button for Mobile */}
          <div
            className={
              toggleMenu
                ? "button_container active navbar-toggler"
                : "button_container navbar-toggler"
            }
            id="toggle"
            onClick={() => setToggleMenu(!toggleMenu)}
          >
            <span className="top"></span>
            <span className="middle"></span>
            <span className="bottom"></span>
          </div>

          {/* Navigation Links */}
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <div className="d-lg-flex justify-content-between w-100">
              {/* Home Link */}
              <div
                className="nav-item"
                style={{ fontWeight: 700, fontSize: 16, marginTop: 10 }}
              >
                <a
                  className="nav-link"
                  href="/"
                  style={{
                    color: "#000000",
                  }}
                >
                  HOME
                </a>
              </div>
              <div
                className="nav-item"
                style={{ fontWeight: 700, fontSize: 16, marginTop: 10 }}
              >
                <a
                  className="nav-link"
                  href="/"
                  style={{
                    color: "#000000",
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    scrollToTerms(); // Scroll to the Privacy section
                  }}
                >
                T&Cs
                </a>
              </div>
              <div
                className="nav-item"
                style={{ fontWeight: 700, fontSize: 16, marginTop: 10 }}
              >
                <a
                  className="nav-link"
                  href="/"
                  onClick={(e) => {
                    e.preventDefault();
                    scrollToPrivacy(); // Scroll to the Privacy section
                  }}
                  style={{
                    color: "#000000",
                  }}
                >
                  PRIVACY POLICY
                </a>
              </div>
              <div
                className="nav-item"
                style={{ fontWeight: 700, fontSize: 16, marginTop: 10 }}
              >
                <a
                  className="nav-link"
                  href="/"
                  style={{
                    color: "#000000",
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    scrollToCookie(); // Scroll to the Privacy section
                  }}
                >
                  COOKIE POLICY
                </a>
              </div>
              {/* <div
                className="nav-item"
                style={{ fontWeight: 700, fontSize: 16, marginTop: 10 }}
              >
                <a
                  className="nav-link"
                  href="/"
                  style={{
                    color: "#000000",
                  }}
                >
                  HOME
                </a>
              </div> */}

              {/* Phone Number */}
              <div
                className="nav-item"
                style={{ fontSize: 24, color: "#00a1c9", marginTop: 4 }}
              >
                +971-4-335-5037
              </div>
            </div>
          </div>
        </div>
      </nav>

      {/* Spacer to adjust for fixed header */}
      <div style={{ height: "140px" }}></div>
    </>
  );
};

export default Header;
