import React from "react";

const Item = ({ title, body, src }) => {
  return (
    <div className="col-lg-6 col-sm-12">
      <div className="row">
        <div className="col-9">
          <div>
            <span
              style={{
                fontWeight: 600,
                fontSize: 22,
                lineHeight: 1.35,
              }}
            >
              {title}
            </span>
          </div>
          <div className="mt-4" style={{ fontWeight: 300, lineHeight: 1.55 }}>
            <span>{body}</span>
          </div>
        </div>
        <div className="col-3 text-center">
          <img
            style={{ maxWidth: "80px", height: "auto" }}
            src={src}
            alt="PHARMACEUTICALS"
          />
        </div>
      </div>
    </div>
  );
};

export default Item;
