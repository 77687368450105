import React from "react";
import BodyText from "../../common/BodyText";
import TitleText from "../../common/TitleText";

const OurMission = ({ reference }) => {
  return (
    <>
      <div ref={reference} style={{ height: 125 }} />
      <div className="text-center">
        <TitleText text={"OUR MISSION"} color="#00000" />
        <div className="mt-3">
          <BodyText
            text={`Our mission at Barx is providing veterinarians in Dubai with the
          resources they need to provide the highest quality of care allowing
          companion animals to stay healthy and active along with providing
          access to a wider range of high-quality pet food.`}
          />
        </div>
      </div>
    </>
  );
};

export default OurMission;
