import React, { useRef, useEffect } from "react";
import { useLocation } from "react-router-dom"
import Header from "./Header";
import Footer from "./Footer";

const TermsAndConditions = () => {
  const location = useLocation();
  const privacyRef = useRef(null); // Create a ref for the Privacy Policy section
  const termsRef = useRef(null); // Create a ref for the Privacy Policy section
  const cookieRef = useRef(null); // Create a ref for the Privacy Policy section

  const scrollToSection = (section) => {
    if (section === "terms" && termsRef.current) {
      termsRef.current.scrollIntoView({ behavior: "smooth" });
    } else if (section === "privacy" && privacyRef.current) {
      privacyRef.current.scrollIntoView({ behavior: "smooth" });
    } else if (section === "cookie" && cookieRef.current) {
      cookieRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  // Scroll to section based on state passed from the parent
  useEffect(() => {
    if (location.state && location.state.scrollTo) {
      scrollToSection(location.state.scrollTo);
    }
  }, [location]);

  // Function to scroll to the privacy section
  const scrollToPrivacy = () => {
    if (privacyRef.current) {
      privacyRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  const scrollToTerms = () => {
    if (termsRef.current) {
      termsRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  const scrollToCookie = () => {
    if (cookieRef.current) {
      cookieRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
    <div ref={termsRef}></div>
    <Header scrollToPrivacy={scrollToPrivacy} scrollToCookie={scrollToCookie} scrollToTerms={scrollToTerms}/>
    <div 
      style={{
        maxWidth: "90%",
        margin: "0 auto",
        // marginTop : "5px",
        marginBottom : "10px",
        padding: "20px",
        paddingTop : "0px",
        fontFamily: "Arial, sans-serif",
        lineHeight: "1.6",
        color: "#333",
      }}
    >
      <h1  style={{ textAlign: "center", color: "#43BAD7", marginBottom : "20px", fontFamily : "sans-serif" }}>TERMS & CONDITIONS</h1>

      <p>
        BARX VETERINARY MEDICINES TRADING & ANIMAL FOOD TRADING L.L.C maintains the{" "}
        <a href="https://barx.ae/" style={{ color: "#007bff", textDecoration: "none" }}>
          https://barx.ae/
        </a>{" "}
        Website (“Site”).
      </p>

      <p>
        “United Arab of Emirates is our country of domicile” and stipulates that the governing law
        is the local law. All disputes arising in connection therewith shall be heard only by a
        court of competent jurisdiction in U.A.E.
      </p>

      <p>Visa or MasterCard debit and credit cards in AED will be accepted for payment.</p>

      <p>
        We will not trade with or provide any services to OFAC (Office of Foreign Assets Control)
        and sanctioned countries in accordance with the law of UAE.
      </p>

      <p>
        Customers using the website who are Minor /under the age of 18 shall not register as a User
        of the website and shall not transact on or use the website.
      </p>

      <p>Cardholders must retain a copy of transaction records and Merchant policies and rules.</p>

      <p>User is responsible for maintaining the confidentiality of his account.</p>

      <h2 style={{  marginTop: "20px" }}>Payment Confirmation</h2>
      <p>
        Once the payment is made, the confirmation notice will be sent to the client via email
        within 24 hours of receipt of payment.
      </p>

      <h2 style={{  marginTop: "20px" }}>Cancellation Policy</h2>
      <p>
        Customers can cancel their order/requested service within 24 hours; refunds will be made
        back to the payment solution used initially by the customer.
      </p>

      <h2 style={{  marginTop: "20px" }}>Refund Policy</h2>
      <p>Refunds will be done only through the Original Mode of Payment. Please allow for up to 45 days for the refund transfer to be completed.</p>

      <h2 style={{  marginTop: "20px" }}>Return and Refund Policy</h2>
      <p>
        We accept returns within 7 days of receipt, only if their delivery packaging has not been
        opened or the products are damaged or the wrong product. Please notify us and return the box
        in its original packaging. In such instances, we will endeavour to send you another or
        refund the payment.
      </p>
      <p>
        We can only accept returns of products that have not been tampered with, are sealed and
        remain in the original packaging. If all these conditions are met, please ship your products
        back to us using a registered courier service and we will issue a full refund. Please note
        that we reserve the right to refuse any returned shipments if the product has been used or
        tampered with. Shipping & Handling fees are non-refundable.
      </p>

      <h2 style={{  marginTop: "20px" }}>Delivery/Shipping Policy</h2>
      <ul>
        <li>For free, same-day delivery in Dubai, complete your order before 9am with a minimum order value of AED 100. Orders completed after 9am will be delivered the next day.</li>
        <li>For Abu Dhabi, Sharjah & Ajman, all orders will be delivered the next day for free if the value is over AED 100.</li>
        <li>For RAK, UAQ and Fujairah, we deliver twice per week for free if the order is above AED 100. Our logistics partner will schedule the exact day with you.</li>
        <li>All Emirates, orders below AED 100, a delivery fee of AED 20 will be added to your order.</li>
        <li ref={privacyRef}>We also offer 4hr express delivery exclusively for Dubai locations at a fee of AED 20. If eligible and you have opted for express delivery, the fee will be added to your order and is in addition to any other shipping fees that may occur. Express delivery is only available for Dubai shipping locations if the order is completed before 4pm. If we are unable to have your order delivered within the expected 4hr time slot, we will refund the express fee.</li>
      </ul>

      <h1  style={{ textAlign: "center", color: "#43BAD7", margin: "47px auto" , fontFamily : "sans-serif"}}>PRIVACY POLICY</h1>
      <p>
        All credit/debit cards’ details and personally identifiable information will NOT be stored,
        sold, shared, rented or leased to any third parties.
      </p>
      <p>https://barx.ae/ will not pass any debit/credit card details to third parties.</p>
      <p>
        https://barx.ae/ takes appropriate steps to ensure data privacy and security including
        through various hardware and software methodologies. However, (https://barx.ae/) cannot
        guarantee the security of any information that is disclosed online.
      </p>
      <p>
        The https://barx.ae/ is not responsible for the privacy policies of websites to which it
        links. If you provide any information to such third parties different rules regarding the
        collection and use of your personal information may apply. You should contact these entities
        directly if you have any questions about their use of the information that they collect.
      </p>
      <p>
        The Website Policies and Terms & Conditions may be changed or updated occasionally to meet
        the requirements and standards. Therefore, the Customers’ are encouraged to frequently visit
        these sections to be updated about the changes on the website. Modifications will be
        effective on the day they are posted.
      </p>

      <h1 ref={cookieRef} style={{ textAlign: "center", color: "#43BAD7", margin: "47px auto", fontFamily : "sans-serif" }}>COOKIE POLICY</h1>
      <p>
        A cookie is a small text file that a website saves on your computer or mobile device when
        you visit the site. It enables the website to remember your actions and preferences (such as
        login, language, font size and other display preferences) over a period of time, so you
        don’t have to keep re-entering them whenever you come back to the site or browse from one
        page to another.
      </p>
    </div>
    <Footer scrollToPrivacy={scrollToPrivacy} scrollToCookie={scrollToCookie} scrollToTerms={scrollToTerms}/>
    </>
  );
};

export default TermsAndConditions;
